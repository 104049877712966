import { LiveData } from "./LiveData";
import { MapSection } from "./MapSection";
import { Box, Button, Flex, Loader, Text } from "@mantine/core";
import { Iconn } from "../../Iconn";
import { colors } from "../../../theme/colors";
import CustomButton from "../../CustomButton";
import { useNavigate, useLocation } from "react-router";
import { InputSection } from "../table-section/InputSection";

export const ShipDetailPage = ({
  liveData,
  onlineTime,
  isError,
  reload,
  isLoading,
  shipName,
  terminalId
  
}) => {
  // const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (isError && isError.response?.status === 500) {
    return (
      <Flex pt="xl" justify="center" direction="column" align="center">
        <Text fw={500} fz={18} ta="center">
          The ship's data could not be loaded. Please try again.
        </Text>
        <CustomButton
          mt={16}
          onClick={reload}
          disabled={isLoading}
          loading={isLoading}
          w={150}
        >
          Try again
        </CustomButton>
      </Flex>
    );
  }

  return (
    <Box p={16}>
      <Flex
        mt={16}
        gap={10}
        justify={{ base: "start", sm: "center" }}
        pb={24}
        pt={{ base: 24, sm: 0 }}
        align="center"
        wrap="wrap"
      >
        <Iconn icon="live" stroke={colors.primaryBlue} />

        <Text fw={500} fz={18}>
          Live data for
        </Text>
 
        <Text fw={600} fz={18}>
          {shipName} 
        </Text>
        <Button
          onClick={reload}
          disabled={isLoading}
          loading={isLoading}
          color="green"
          radius="xl"
        >
         Refresh data
        </Button>
      </Flex>

      <LiveData liveData={liveData} onlineTime={onlineTime} />
      <MapSection liveData={liveData} />

      <InputSection terminalId={terminalId} />

      {/* <Flex justify="center" pt={40}>
        <CustomButton
          pl={3}
          leftSection={<Iconn icon="arrowLeft" size={24} />}
          maw={{ base: "100%", sm: 200 }}
          onClick={() => navigate("/dashboard")}
        >
          Back to dashboard
        </CustomButton>
      </Flex> */}
    </Box>
  );
};
