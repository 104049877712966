import { Accordion, Flex, Loader, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useGetFleet } from "../../hooks/useGetFleet";
import { Group } from "lucide";

export const AccordionGlobalSection = ({items}) => {
  const navigate = useNavigate();
  //const { fleetDetails } = useGetFleet();

  

  if (!items)
    return (
      <Flex py={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  

  return (
      <Flex
        mt={15}
        gap={10}
        justify="center"
        py="md"
        pos="relative"
        bg="#f2f2f2"
        style={{ borderRadius: "15px" }}
        direction="column"
      >
        
        <Text fw={600} fz={20} py={15} ta="center">
          Fleet List
        </Text>

        <Accordion
          mb={{ base: 32, sm: 0 }}
          variant="contained"
          chevronSize={24}
          radius={0}
          styles={{
            root: {
              border: 0,
            },
            item: {
              border: 0,
            },
            control: {
              border: "1px solid #ECECEC",
              background: "#f2f2f2",
              boxShadow: "0px 4px 4px 0px rgba(85, 60, 31, 0.02)",
            },
          }}
        >
          {items}
        </Accordion>
      </Flex>
  );
};
