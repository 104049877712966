import axiosClient from "./axiosClient";


function get(url) {
  const token = sessionStorage.getItem("authToken");
  if (!token) throw new Error("token is not present");

  return axiosClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.log("Fetching data failed, retrying...", error);
      return axiosClient.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
}

function post(url, payload) {
  const token = sessionStorage.getItem("authToken");
  if (!token) throw new Error("token is not present");

  return axiosClient
    .post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

function patch(url, payload) {
  const token = sessionStorage.getItem("authToken");
  if (!token) throw new Error("token is not present");

  return axiosClient
    .patch(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

function _delete(url) {
  const token = sessionStorage.getItem("authToken");
  if (!token) throw new Error("token is not present");

  return axiosClient
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

const api = {
  get,
  post,
  patch,
  delete: _delete,
};
export default api;
