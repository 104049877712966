import React, { useEffect, useState,useRef } from "react";
import { Box, Grid } from "@mantine/core";
import { GlobalMap } from "./GlobalMap";
import api from "../../api"
import { AccordionGlobalSection } from "./AccordionGlobalSection";
import { Accordion, Flex, Text,Avatar,Group,Loader } from "@mantine/core";
import { Icon } from "leaflet";
import { useNavigate } from "react-router-dom";
import { Monitoring } from "../../pages/Monitoring";
import { colors } from "../../theme/colors";
// const Component1 = lazy(() =>
//   import("./AccordionGlobalSection").then((module) => ({
//     default: module.AccordionGlobalSection,
//   }))
// );

export const DashboardPage = () => {
  const navigate = useNavigate();
  const [ fleetDetails,setFleetDetails ] = useState([])
  const [dataArray,setDataArray]=useState([])
  const [items,setItems]=useState([])
  const firstLoad = useRef(true);
  const [showMap, setShowMap] = useState(true);

  const [isLoading, setIsLoading] = useState(false); 
  const loading = useRef(false); 

  const setLoading = (isLoading) => {
    loading.current = isLoading;
    setIsLoading(false); 
  };
  
  
  const handleToggle = (ship) => {
    if (ship) {
      sessionStorage.setItem("selectedShip", JSON.stringify(ship));
    } else {
        sessionStorage.removeItem("selectedShip");
    }

    setShowMap(ship ? false : true);
    setIsLoading(true)
  }; 

  const numberOfShips=fleetDetails.map(x=>x.ships.length).reduce((accumulator, current) => accumulator + current, 0)


  const redIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3138/3138846.png",
    iconSize: [38, 38],
  });

  const greenIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/5996/5996374.png",
    iconSize: [38, 38],
  });

  const orangeIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3794/3794134.png",
    iconSize: [35, 35],
  });

  useEffect(()=>{
    if(sessionStorage.getItem("fleetDetails")!=null){
      setFleetDetails(JSON.parse(sessionStorage.getItem("fleetDetails")))
      return
    }
    api.get("terminals/listing")
    .then(response=>{
      setFleetDetails(response.data)
      sessionStorage.setItem("fleetDetails",JSON.stringify(response.data))
    })
    .catch(error=>{
      if(error.response.status==401){
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
      console.log(error)
    })
  },[])
 

  useEffect(()=>{
    if(fleetDetails.length>0 && firstLoad.current){
      firstLoad.current=false
      fleetDetails.map((fleet) => (
        fleet.ships.map((ship,index)=>{
          api.get(
            `terminals/current-position-for-ship/${ship.terminal.terminal_id}`
          ).then(response=>{
            if (!dataArray.some((item) => item.terminal_id == response.data.terminal_id)) {
              ship["online"]=response.data.online
              if(fleet.fleet[response.data.online]){
                fleet.fleet[response.data.online]++
              }else{
                fleet.fleet[response.data.online]=1
              }
              setDataArray((prevArray) => [...prevArray, response.data])
              generateItems()
            }
          }).catch(error=>{
            if(error.response.status==401){
              sessionStorage.clear();
              localStorage.clear();
              navigate("/");
            }
            console.log(error)
          })
        })
      ))
    }
  },[fleetDetails])

  const AccordionLabel=({label,icon})=>{
    return (
      <Group direction="row" wrap="nowrap">
          <Avatar src={icon.options.iconUrl} size="xs" />
          <Text>{label}</Text>
      </Group>
    );
  }

  const colorForShip=(status)=>{
    if(status=="online"){
      return "green"
    }
    if(status=="offline"){
      return "red"
    }
    if(status="provisioned"){
      return "orange"
    }
    return null
  }

  const generateItems = () => {
    const generatedItems = fleetDetails.map((item, index) => (
      <Accordion.Item key={index} value={item.fleet.fleet_name}>
        <Accordion.Control>
        <Group direction="row" wrap="noWrap">  
        {item.fleet.fleet_name}
        {item.fleet.online > 0 && (
          <AccordionLabel label={item.fleet.online} icon={greenIcon} />
        )}
        {item.fleet.offline > 0 && (
          <AccordionLabel label={item.fleet.offline} icon={redIcon} />
        )}
        {item.fleet.provisioned > 0 && (
          <AccordionLabel label={item.fleet.provisioned} icon={orangeIcon} />
        )}
        </Group>
          </Accordion.Control>
        <Accordion.Panel>
          {item.ships.map((ship, index) => (
            <Flex direction="column" key={index}>
              <Text
                style={{ cursor: "pointer",
                  color:colorForShip(ship.online) || "gray"
                }}
                onClick={() =>
                  //handleShipClick(ship.terminal.terminal_id, ship.ship_name)
                  handleToggle(ship)
                }
                py={7}
              >
                {ship.ship_name}
              </Text>
            </Flex>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    ));

    setItems(generatedItems); // Postavi generisane stavke u stanje
  };

  const handleShipClick = (terminalId, shipName) => {
    navigate(`/ship/${terminalId}?name=${encodeURIComponent(shipName)}`);
  };

  // Call the hook to download ship positions when fleetDetails are available
  //useDownloadShipPositions(fleetDetails?.data, setMapData);

  return (
    <Box px={{ base: 16, sm: 32 }} py={{ base: 16, sm: 32 }}>
      <Grid justify="space-between">
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <AccordionGlobalSection items={items} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 9 }}>
        {false ? 
        (<Flex
          pos="relative"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justify="center"
          align="center"
          style={{ zIndex: 1000 }}
          direction="column"
          gap="sm"
        >
          <Loader color={colors.primaryBlue} size="lg" />
          <Text fz="sm" c={colors.lightText}> {loading.current}
          {loading.current && 'Loading ...'}
          </Text>
        </Flex>) :
         (showMap ? (
          <GlobalMap 
            numberOfLoaded={dataArray.length} 
            numberOfShips={numberOfShips} 
            dataArray={dataArray} 
          />
        ) : (
          <Monitoring />
        ))}
          
          
        </Grid.Col>
      </Grid>
    </Box>
  );
};

// import { Box, Grid } from "@mantine/core";
// import React from "react";
// import { Suspense, lazy } from "react";
// import { GlobalMap } from "./GlobalMap";
// const Component1 = lazy(() =>
//   import("./AccordionGlobalSection").then((module) => ({
//     default: module.AccordionGlobalSection,
//   }))
// );

// export const DashboardPage = () => {
//   return (
//     <Box px={{ base: 16, sm: 32 }} py={{ base: 16, sm: 32 }}>
//       <Grid justify="space-between">
//         <Grid.Col span={{ base: 12, lg: 3 }}>
//           <Suspense fallback={<div>Loading please wait...</div>}>
//             <Component1 />
//           </Suspense>
//         </Grid.Col>
//         <Grid.Col span={{ base: 12, lg: 9 }}>
//           <GlobalMap />
//         </Grid.Col>
//       </Grid>
//     </Box>
//   );
// };
