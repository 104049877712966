import { Button, Flex, Text } from "@mantine/core";
import { Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { Iconn } from "../Iconn";
import { Link } from "react-router-dom";
import { colors } from "../../theme/colors";

export const MapMarker =({ data }) => {
  // const { coordinates, isLoading, isError } = useGetShipCoordinates(
  //   ship.terminal_id
  // );
 
  // const [data, setData] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const [isError, setIsError] = useState(false);

  // useEffect(()=>{
  //   api.get(
  //     `terminals/current-position-for-ship/${terminal_id}`
  //   ).then(response=>{
  //     setData(response.data)
  //     setIsLoading(false)
  //   }).catch(error=>{
  //     setIsError(error)
  //   })
  // },[])
  

  const redIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3138/3138846.png",
    iconSize: [38, 38],
  });

  const greenIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/5996/5996374.png",
    iconSize: [38, 38],
  });

  const orangeIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3794/3794134.png",
    iconSize: [35, 35],
  });
  
  // if (isLoading) return null;
  // if (
  //   isError ||
  //   !data ||
  //   !data.latitude ||
  //   !data.longitude
  // ) {
  //   return null
  // }

  // if (isError) {
  //   return null;
  // }

  return (
    <Marker
      key={data.terminal_id}
      position={[data.latitude, data.longitude]}
      icon={
        data.online === "online"
          ? greenIcon
          : data.online === "offline"
          ? redIcon
          : orangeIcon
      }
    >
      <Popup>
        <Text fz={14} fw={600}>
          {data.ship_name}
        </Text>
        <Text fz={14}>Latitude: {data.latitude}</Text>
        <Text fz={14}>Longitude: {data.longitude}</Text>

        {/* <Flex style={{ cursor: "pointer" }} align="center" justify="center">
          <Text
            fw={400}
            my={0}
            fz={12}
            component={Link}
            to={`/ship/${data.terminal_id}`}
            c={colors.primaryBlue}
          >
            View all details
          </Text>
          <Iconn icon="chevronRight" size={16} stroke={colors.primaryBlue} />
        </Flex> */}
      </Popup>
    </Marker>
  );
};
