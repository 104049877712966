import { Box, Grid } from "@mantine/core";
import { ShipDetailPage } from "../components/monitoring/right-section/ShipDetailPage";
import { useParams } from "react-router-dom";
import useGetShipLiveData from "../hooks/useGetShipLiveData";
import useGetOnlineTime from "../hooks/useGetOnlineTime";
import { useGetFleet } from "../hooks/useGetFleet";
import { AccordionSection } from "../components/monitoring/AccordionSection";
import { useEffect } from "react";
import api from "../api";

export const Monitoring = ({setLoading}) => {
  const ship=JSON.parse(sessionStorage.getItem("selectedShip"))
  const ship_name=ship.ship_name
  const terminal_id=ship.terminal.terminal_id

  const { liveData, isError, reload, isLoading, setShouldRetry } =
    useGetShipLiveData(terminal_id);
  const { onlineTime } = useGetOnlineTime(terminal_id);

  return (
    <Box px={{ base: 0, sm: 24 }}>
      <Grid justify="space-between">
        {/* <Grid.Col span={{ base: 12, lg: 3 }}>
          <AccordionSection fleetDetails={fleetDetails} />
        </Grid.Col> */}
        <Grid.Col span={{ base: 12, lg: 9 }}>
          <ShipDetailPage
            liveData={liveData}
            onlineTime={onlineTime}
            isError={isError}
            reload={reload}
            isLoading={isLoading}
            setShouldRetry={setShouldRetry}
            shipName={ship_name}
            terminalId={terminal_id}
            setLoading={setLoading}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
