const inputProps = {
  input: {
    height: 40,
    radius: 8,
    fontWeight: 500,
  },
};

export const theme = {
  fontFamily: "Montserrat, sans-serif",
  colorScheme: "light",
  spacing: {
    paper: "24px",
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "24px",
    xl: "40px",
  },
  primaryColor: "blue",
  primaryShade: 3,
  defaultRadius: "md",

  components: {
    Text: {
      defaultProps: {
        color: "#333",
      },
    },

    TextInput: {
      styles: inputProps,
      "&:focus-within": {
        borderColor: "blue",
      },
    },
    Select: {
      styles: inputProps,
    },
    DateTimePicker: {
      styles: inputProps,
    },
  },
};
