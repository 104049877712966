import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { Flex, Loader, Text } from "@mantine/core";

export const MapSection = ({ liveData, }) => {
  if (!liveData)
    return (
      <Flex py={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  const ship = liveData.data;
  const shipName = liveData.data.ship_name;

  const position = [
    ship.current_position.latitude,
    ship.current_position.longitude,
  ];

  const center = position;

  const redIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3138/3138846.png",
    iconSize: [38, 38],
  });

  const greenIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/5996/5996374.png",
    iconSize: [38, 38],
  });

  const orangeIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3794/3794134.png",
    // iconUrl: "https://cdn-icons-png.flaticon.com/128/727/727606.png",
    iconSize: [38, 38],
  });

  return (
      <MapContainer
        center={center}
        zoom={9}
        style={{
          height: "450px",
          width: "100%",
          borderRadius: "15px",
          zIndex: 3,
        }}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={position}
          icon={
            ship.aggregated_status === "online"
              ? greenIcon
              : ship.aggregated_status === "offline"
              ? redIcon
              : orangeIcon
          }
        >
          <Tooltip>
            <Text fz={14} my={5} fw={600} tt="uppercase" ta="center">
              {shipName}
            </Text>
            <Text fz={14} my={5}>
              Latitude: {ship.current_position.latitude.toFixed(4)}
            </Text>
            <Text fz={14} my={5}>
              Longitude: {ship.current_position.longitude.toFixed(4)}
            </Text>
          </Tooltip>
        </Marker>
      </MapContainer>
  );
};
