import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Box, Button, Flex, Loader, Text } from "@mantine/core";
import { Iconn } from "../Iconn";
import { colors } from "../../theme/colors";
import { useGetShips } from "../../hooks/useGetShips";
import { MapMarker } from "./MapMarker";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useEffect, useState,useCallback } from "react";
import api from "../../api"

export const GlobalMap = ({numberOfLoaded,numberOfShips,dataArray}) => {
  
  
  //const { isLoading, isError } = useGetShips();
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  

  // if (isError) {
  //   return (
  //     <Text fz="md" ta="center">
  //       Unable to load ship data. Please try again later.
  //     </Text>
  //   );
  // }

  return (
    <Box pos="relative">
      <Flex gap={5} justify="center" pt={{ base: 40, sm: 0 }} direction={"column"} align="center">
        <Text pb="sm" fw={600} fz={35} c="cyan">
          Global map
        </Text>
        { numberOfLoaded<numberOfShips?
        
        <Text pb="sm" fw={500} fz={20} c="black">
          Now load {numberOfLoaded}/{numberOfShips}
        </Text>
        :null
        }
      </Flex>

      <Box pos="relative">
        {/* {isLoading && (
          <Flex
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justify="center"
            align="center"
            style={{ zIndex: 1000 }}
            direction="column"
            gap="sm"
          >
            <Loader color={colors.primaryBlue} size="lg" />
            <Text fz="sm" c={colors.lightText}>
              Loading the position of the ships...
            </Text>
          </Flex>
        )} */}

        <MapContainer
          center={[20, 0]}
          zoom={2}
          style={{
            height: "510px",
            width: "100%",
            zIndex: 1,
            borderRadius: "10px",
          }}
          attributionControl={false}
        >
          <TileLayer
            attributionControl={false}
            attribution={null}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {dataArray.map((data,index)=>{
            if(data.latitude && data.longitude){
              return <MapMarker key={index} data={data} />
            }
          })
          }
        </MapContainer>
      </Box>

      <Flex
        justify="end"
        pos="sticky"
        bottom={20}
        right={0}
        style={{ zIndex: 5 }}
      >
        <Button
          style={{
            boxShadow: "0px 4px 40px 0px rgba(41,41,41,1)",
            zIndex: 5,
          }}
          hiddenFrom="md"
          radius="50%"
          w="60px"
          h="60px"
          p={0}
          onClick={scrollToTop}
        >
          <Iconn icon="arrowUp" size={42} />
        </Button>
      </Flex>
    </Box>
  );
};
