import useSWR, { mutate } from "swr";
import api from "../api";
import { useNavigate } from "react-router-dom";

const fetcher = async (id) => {
  const data = await api.get(id);
  return data;
};

const useGetShipLiveData = (id) => {
  const navigate = useNavigate();

  const { data, error, isValidating } = useSWR(
    `terminals/live-data/${id}`,
    fetcher,
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  if (error && error.response && error.response.status === 401) {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }
  return {
    liveData: data,
    isLoading: isValidating,
    isError: error,
    reload: () => mutate(`terminals/live-data/${id}`),
  };
};

export default useGetShipLiveData;
