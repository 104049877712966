import { Route } from "react-router-dom";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { GuestGuard } from "../routes/guards/GuestGuard";
import { Login } from "../pages/Login";
import { AuthGuard } from "../routes/guards/AuthGuard";
import { Layout } from "./layout/Layout";
import { Dashboard } from "../pages/Dashboard";
import { Monitoring } from "../pages/Monitoring";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuestGuard>
            <Login />
          </GuestGuard>
        }
      />
      <Route
        path="/"
        element={
          <AuthGuard>
            <Layout />
          </AuthGuard>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/ship/:id" element={<Monitoring />} /> */}
      </Route>
    </Routes>
  );
};
